<template>
  <div>
    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext="'Service Status'" :subtext="'Aktueller Status der Datendienste'"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3">
            <b-row>
              <b-col md="12" class="text-left">
                <h4 class="text-uppercase h4-color pt-4">Kern-Dienste</h4>
                <p>Diese Dienste liefern Daten für die Kernfunktionen der Seite.</p>
                <b-container class="card" style="margin-bottom: 5px">
                  <b-row class="card-body">
                    <b-col md="3"><strong>Fonds</strong></b-col>
                    <b-col md="6">Daten für die Fondsliste und Fondsdetails</b-col>
                    <b-col md="1">
                      <b-badge :variant="produktezwm.status">
                        <span v-if="produktezwm.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="produktezwm.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{produktezwm.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Authentifizierung</strong></b-col>
                    <b-col md="6">Authentifizierung und Authorisierung</b-col>
                    <b-col md="1">
                      <b-badge :variant="auth.status">
                        <span v-if="auth.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="auth.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{auth.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Aufträge</strong></b-col>
                    <b-col md="6">Daten für die Käufermail, Auftragsliste, Auftragsdetails und Auftragsverwaltung</b-col>
                    <b-col md="1">
                      <b-badge :variant="auftraege.status">
                        <span v-if="auftraege.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="auftraege.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{auftraege.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Vermittlungen</strong></b-col>
                    <b-col md="6">Daten für die Vermittlungsliste und Vermittlungsdetails</b-col>
                    <b-col md="1">
                      <b-badge :variant="vermittlungen.status">
                        <span v-if="vermittlungen.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="vermittlungen.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{vermittlungen.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Logins</strong></b-col>
                    <b-col md="6">Daten für die Passwortverwaltung, Newsletter und Registrierung</b-col>
                    <b-col md="1">
                      <b-badge :variant="logins.status">
                        <span v-if="logins.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="logins.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{logins.responseTime}}s)</b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3">
            <b-row>
              <b-col md="12" class="text-left">
                <h4 class="text-uppercase h4-color pt-4">Weitere Dienste</h4>
                <p>Diese Dienste liefern Daten für weitere Funktionen und Features der Seite.</p>
                <b-container class="card" style="margin-bottom: 5px">
                  <b-row class="card-body">
                    <b-col md="3"><strong>Stammdaten</strong></b-col>
                    <b-col md="6">Daten für das Benutzerprofil und die Registrierung</b-col>
                    <b-col md="1">
                      <b-badge :variant="stammdaten.status">
                        <span v-if="stammdaten.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="stammdaten.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{stammdaten.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Registrierung</strong></b-col>
                    <b-col md="6">Registrierung</b-col>
                    <b-col md="1">
                      <b-badge :variant="registration.status">
                        <span v-if="registration.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="registration.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{registration.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Beobachtungen</strong></b-col>
                    <b-col md="6">Daten für die Fondsbeobachtung</b-col>
                    <b-col md="1">
                      <b-badge :variant="produkte.status">
                        <span v-if="produkte.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="produkte.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{produkte.responseTime}}s)</b-col>
                  </b-row>
                  <b-row class="card-body">
                    <b-col md="3"><strong>Content</strong></b-col>
                    <b-col md="6">Daten für Infotexte und Tooltips</b-col>
                    <b-col md="1">
                      <b-badge :variant="content.status">
                        <span v-if="content.status === 'secondary'">Wird geprüft...</span>
                        <span v-else-if="content.status === 'success'">OK</span>
                        <span v-else>Fehler</span>
                      </b-badge>
                    </b-col>
                    <b-col md="2">(Ping: {{content.responseTime}}s)</b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3 pb-3">
            <b-row>
              <b-col md="12" class="text-left">
                <p>
                  Einige Dienste haben zusätzliche, interne, Abhängigkeiten zu weiteren Diensten. Diese Zusatzdienste
                  werden nicht explizit geprüft, es kann also trotz eines Status <b-badge variant="success">OK</b-badge>
                  zu Fehlern im Ablauf eines Prozesses kommen.
                </p>
                <p>Antwortzeiten werden sowohl durch den Serverzustand als auch von der Internetverbindung beeinflusst.</p>
              </b-col>
            </b-row>
        </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";

export default {
  name: "BackendServiceStatus",
  components: {Hero, HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  data() {
    return {
      error: null,
      produktezwm: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_HEALTH,
        errorVariant: 'danger',
        responseTime: undefined,
      }, 
      produkte: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_PRODUKTE_HEALTH,
        errorVariant: 'warning',
        responseTime: undefined,
      },
      auth: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_AUTH_HEALTH,
        errorVariant: 'danger',
        responseTime: undefined,
      },
      auftraege: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_AUFTRAEGE_HEALTH,
        errorVariant: 'danger',
        responseTime: undefined,
      },
      vermittlungen: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_VERMITTLUNGEN_HEALTH,
        errorVariant: 'danger',
        responseTime: undefined,
      },
      logins: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_LOGINS_HEALTH,
        errorVariant: 'danger',
        responseTime: undefined,
      },
      stammdaten: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_STAMMDATEN_HEALTH,
        errorVariant: 'warning',
        responseTime: undefined,
      },
      content: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT_HEALTH,
        errorVariant: 'warning',
        responseTime: undefined,
      },
      registration: {
        status: 'secondary',
        url: this.$store.getters.getAllEnvis.API_REGISTRATION_HEALTH,
        errorVariant: 'warning',
        responseTime: undefined,
      },
    }
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    checkStatus( url ) {
      return axios.get( url );
    },
    // eslint-disable-next-line no-unused-vars
    async checkServiceStatus( serviceItem, index, array ) {
      var startRequest = Date.now();
      serviceItem.status = await this.checkStatus( serviceItem.url )
          .then( () => {
                serviceItem.responseTime = (Date.now() - startRequest) / 1000;
                return 'success'
              },
            error => {
              serviceItem.responseTime = (Date.now() - startRequest) / 1000;
              console.log( 'Error checking status of ' + serviceItem.url )
              console.log( error )
              return serviceItem.errorVariant
            } )
    },
    async checkAllStatus() {
      const services = [this.produktezwm,this.produkte,this.auth,this.auftraege,this.vermittlungen,
        this.content,this.logins,this.stammdaten,this.registration];
      services.forEach(this.checkServiceStatus);
    }
  },
  created() {
    this.checkAllStatus();  
  }
}
</script>

<style scoped>

</style>